import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { doLogin } from "../utils/api";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("site") || "");
  const navigate = useNavigate();
  const loginAction = async (input) => {    
    try {
      const { data, error } = await doLogin(input.username, input.password);
      if (error){        
        throw new Error(error.message);
      }
      else {
        setUser(data.user);
        setToken(data.jwt);
        localStorage.setItem("site", data.jwt);
        navigate("/cm");
        return;
      }  
    } catch( err ){
        
    }
          
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("site");
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};