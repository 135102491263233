
import React from "react";
import SimpleContentSection from "./SimpleContentSection";

export default class Padel extends React.Component {
 

  render() {
    
    return <section className="section-page">
              <div className="container">
                <div className="section-title pb-0">
                    <h2>Padel</h2>
                  </div>   

                <SimpleContentSection path="/padel" ></SimpleContentSection>
              </div>            
          </section>
  }

}