import React from "react";
import { Link } from 'react-router-dom';

export default function Footer()  {
 
    return (<section className="">
            <div className="container">

              <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                <div className="col-md-4 d-flex align-items-center">
                  <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                    
                  </a>
                  <span className="mb-3 mb-md-0 text-muted">&copy; 2024 TC Angertal</span>
                </div>

                <ul className="nav col-md-4 justify-content-end">
                  <li className="nav-item"><Link to="/impressum" className="nav-link px-2 text-muted">Impressum</Link></li>
                  <li className="nav-item"><Link to="/datenschutz" className="nav-link px-2 text-muted">Datenschutz</Link></li>                  
                </ul>

              </footer>
           
            </div></section>
         
            );
  
}