import React from "react";
import SimpleContentSection from "../components/SimpleContentSection";

export default function RobinsonCupView()  {
  
    return (
        <section className="section-page">
              <div className="container">
                <SimpleContentSection path="/robinsoncup" ></SimpleContentSection>
              </div>
        </section>
     );
}