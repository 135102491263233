
import React from "react";


export default function LKRace()  {

    return (
      
        <div className="container">
          <div className="row justify-content-center">
                <div className="col-md-8">
                  <iframe src="https://mybigpoint.tennis.de/services/?action=lkracenv&verband=TVN&cnt=30&verein=3061" width="300" height="500" frameborder="0" scrolling="no"></iframe>
                </div>
          </div>
        </div>
      
    )

}