import React from "react";
import NewsHomeView from './NewsHomeView';
import Contact from '../components/Contact';
import Counter from '../components/Counter';
import TennisPadelSection from '../components/TennisPadelSection';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IntroGastro from "../components/IntroGastro";
import StartseiteBilder from "../components/StartseiteBilder";
import PartnerSection from "../components/PartnerSection";

export default class HomeView extends React.Component { 

  render() {    
    return  <>     
            <header className="masthead">
                <div className="container px-3 px-lg-5 h-100">
                    <div className="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
                        <div className="col-lg-8 align-self-end">
                            <h1 className="text-white font-weight-bold">TC Angertal</h1>
                            <hr className="divider" />
                        </div>
                        <div className="col-lg-8 align-self-baseline">
                            <h3 className="text-white-75 mb-5">TENNIS & PADEL CLUB</h3>
                            <FontAwesomeIcon icon="angles-down" size="3x" className="text-white-75 mb-5"></FontAwesomeIcon>
                        </div>
                    </div>
                </div>
            </header>
            
            <Counter />      
            <NewsHomeView />                      
            <TennisPadelSection />       
            <StartseiteBilder />            
            <PartnerSection />
            <Contact/>
            </>;

  }
}