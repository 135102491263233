import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../assets/img/logo.png';

function Navbar3() {
  return (
    <Navbar bg="light" expand="lg" className="navbar navbar-expand-lg navbar-light fixed-top py-3">
      <Container>
        <Navbar.Brand href="/" className="navbar-brand">
            <img src={Logo} height="45" alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav >
            <NavDropdown title="Club" id="basic-nav-dropdown">
              <NavDropdown.Item href="/club">Clubinfos</NavDropdown.Item>
               <NavDropdown.Item href="/tennis">Tennis</NavDropdown.Item>              
               <NavDropdown.Item href="/padel">Padel</NavDropdown.Item>              
               <NavDropdown.Item href="/galerie">Galerie</NavDropdown.Item>                             
            </NavDropdown>
            <Nav.Link href="/termine">Termine</Nav.Link>
            <Nav.Link href="/mitgliedwerden">Mitglied werden</Nav.Link>
            <Nav.Link href="/partner">Partner</Nav.Link>
            <Nav.Link href="https://tc-angertal.ebusy.de" target="_blank" rel="noreferrer">Plätze buchen</Nav.Link>            
            <Nav.Link href="https://tvn.liga.nu/cgi-bin/WebObjects/nuLigaTENDE.woa/wa/clubTeams?club=36102" target="_blank" rel="noreferrer">Mannschaften</Nav.Link>
            
            
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navbar3;