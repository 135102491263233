
import React from "react";
import SimpleContentSection from "./SimpleContentSection";
import ClubVorstand from "./ClubVorstand";

export default class Club extends React.Component {
 

  render() {
    
    return <section className="section-page">      
              <div className="container">
                <div className="section-title pb-0">
                    <h2>Club</h2>
                  </div>
                <header className="d-flex justify-content-center pt-0">
                  <ul className="nav nav-pills">                    
                    <li className="nav-item"><a href="#vorstand" className="nav-link">Vorstand</a></li>
                    <li className="nav-item"><a href="#dokumente" className="nav-link">Dokumente</a></li>
                    <li className="nav-item"><a href="#erimashop" className="nav-link">Erima Shop</a></li>                                        
                    <li className="nav-item"><a href="#tischtennisplatte" className="nav-link">Tischtennisplatte</a></li>
                    <li className="nav-item"><a href="#geschichte" className="nav-link">Geschichte</a></li>                    
                  </ul>
                </header>

                <ClubVorstand></ClubVorstand>
                <SimpleContentSection path="/club" ></SimpleContentSection>
                
              </div>   

          </section>
  }

}