
import React, { useEffect, useState } from "react";
import { useParams  } from "react-router-dom";
import { getNewsItem } from "../utils/api"
import ImageRenderer from "../components/ImageRenderer";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

export default function NewsItemView() {
    const param = useParams();

    const [newsItemState,setNewsItemState] = useState({});    
 
    useEffect( () => {

      const fetchData = async () => {
        try {                    
          const res = await getNewsItem(param.id);                    
          setNewsItemState(res)          
        } catch (e) {
          console.log(`Axios request failed: ${e}`);
        }
      }
      fetchData()
         
      }, [param.id]
    )

    return (<section className="section-page">
      <div className="container">
               
        <div className="row align-items-center pt-2">
        <div className="col">

        <div className="card h-100">                                      
           <div className="card-header">
           {newsItemState.titel}
            </div>                               
           <div className="card-body">
           <ReactMarkdown components={{ img: ImageRenderer }} rehypePlugins={[rehypeRaw]}>{newsItemState.inhalt}</ReactMarkdown>

          </div>

        </div>
        </div>
        </div>
        
        </div></section>);
  
}