
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Contact extends React.Component {
 

  render() {
    
    return <section className="text-center section-padding contact-wrap" id="contact">        
        <div className="container">
        
          <div className="row contact-details">
    
            <div className="col-md-4">
              <div className="dark-box box-hover">
                <h2><FontAwesomeIcon icon="location-dot" /><span>Adresse</span></h2>
                <p>Ernst-Stinshoff-Straße 50, 40883 Ratingen</p>
              </div>
            </div>
    
            <div className="col-md-4">
              <div className="dark-box box-hover">
                <h2><FontAwesomeIcon icon="phone" /><span>Telefon</span></h2>
                <p>02102/69988 (Mo 14:15-17:30 Uhr, Di 14:45-17:30 Uhr, Mi 10-11:30 Uhr) </p>                 
              </div>
            </div>      
            <div className="col-md-4">
              <div className="dark-box box-hover">
                <h2><FontAwesomeIcon icon="paper-plane" /> <span>Email</span></h2>
                <p>info[at]tc-angertal[dot]de</p>
              </div>
            </div>
          </div>

          <div className="row">     
            <div className="col-md-6">
           {/* <iframe  className="w-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d907.4179938916174!2d6.899181300310023!3d51.3203145850178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8c5f3cc982d9f%3A0xf829fea6291a1c4f!2sTennisclub%20Angertal%20e.V.!5e0!3m2!1sde!2sde!4v1644238567990!5m2!1sde!2sde"  loading="lazy" ></iframe> */}
            </div>
            <div className="col-md-6">
            {/*<div className="dark-box box-hover">
                <h2> <span>Mastodon</span></h2>
                <p></p>
              </div>*/}
            </div>
          </div>

          </div>
        </section>;
  }

}