import React from "react";
import SimpleContentSection from "./SimpleContentSection";
import Accordion from 'react-bootstrap/Accordion';

export default function MitgliedWerden()  {
   
    return (<section className="section-page">
              <div className="container">

              <div className="section-title">
                <h2>Mitglied werden</h2>
              </div>

              <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Möchtest du Tennis spielen?</Accordion.Header>
                <Accordion.Body>
                  <SimpleContentSection path="/mitglied-werden"></SimpleContentSection>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Möchtest du Padel spielen?</Accordion.Header>
                <Accordion.Body>
                  <SimpleContentSection path="/mitglied-werden" queryField="padel" ></SimpleContentSection>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Oder einfach BEIDES?</Accordion.Header>
                <Accordion.Body>
                <SimpleContentSection path="/mitglied-werden" queryField="beides" ></SimpleContentSection>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
                
               </div>
               </section>
            );
  
}