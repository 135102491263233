import React, { useEffect, useState } from "react";
import { getPartner, getPartnerImage } from "../utils/api"

export default function Partner()  {

  const [partnerState,setPartnerState] = useState([]);
 
  useEffect( () => {

    const fetchData = async () => {
      try {
        const data = await getPartner();                  
        setPartnerState(data.data)
      } catch (e) {
        console.log(`Axios request failed: ${e}`);
      }
    }
    fetchData()
       
    }, []
  )
   
    return (<section className="section-page">
              <div className="container">

              <div className="section-title">
                <h2>Partner & Sponsoren</h2>
              </div>

                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4">

                  {partnerState.map((item,i) => (
                    <div className="col" key={i}>
                      <div className="card my-1 p-0">
                      
                          <div className="card-body" align="center">
                            <img src={getPartnerImage(item)} alt="Partner logo" />

                            <a href={item.attributes.homepage} target="_blank" rel="noreferrer">Webseite</a>
                          </div>
                      
                      </div>
                    </div>
                  ))
                  }
                                  
                </div> 
               </div>
               </section>
            );
  
}