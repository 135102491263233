import React from "react";
import SimpleContentSection from './SimpleContentSection';

export default function Datenschutz()  {
   
    return (<section className="section-page">
              <div className="container">

              <div className="section-title">
                <h2>Datenschutz</h2>
              </div>

              <SimpleContentSection path="/datenschutz" bgcolor="" />
                
               </div>
               </section>
            );
  
}