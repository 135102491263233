
import React from "react";
import Carousel from 'better-react-carousel';
import { getGalleryItems, getStrapiURL } from "../utils/api";

export default class Gallery extends React.Component {

  state = {      
    images: [],    
  };
 

  render() {
    const { images = [] } = this.state;
    return <section className="section-page">
      
              <div className="section-title pb-0">
                    <h2>Gallerie</h2>
                  </div>

            <Carousel cols={2} rows={1} gap={10} loop>
              {images.map((item,i) => (

                <Carousel.Item key={i}>
                  <img width="100%" src={item} alt="bild"/>
                </Carousel.Item>

                ))
              }

            </Carousel>
          </section>
  }

  async componentDidMount() {
    
    try {
      const items = await getGalleryItems();
      // console.log(items);
      let imgs = [];
      items.forEach(obj => {          
          imgs.push(getStrapiURL('') + obj['attributes']['formats']['medium']['url']);     
               
      });
      console.log(imgs); 
      this.setState({ images : imgs })

    } catch (e) {
      console.log(`Axios request failed: ${e}`);
    }

  }

}