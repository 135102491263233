import axios from 'axios';

  export function getStrapiURL(path) {
    return `${
      process.env.REACT_APP_STRAPI_API_URL || "http://localhost:1337"
    }${path}`;
  }
  
  // Helper to make GET requests to Strapi
  export async function fetchAPI(path) {
    const requestUrl = getStrapiURL(path);
    const response = await fetch(requestUrl);
    const data = await response.json();
    return data;
  }

  export async function fetchSimpleContentAPI(path) {
    const requestUrl = getStrapiURL(path);
    const response = await axios.get(requestUrl);
    return response.data;    
  }
  
  export async function getNews() {
    const news = await fetchAPI("/aktuelles?populate=%2A&sort[0]=updatedAt%3Adesc&pagination[1]=1&pagination[pageSize]=4");
    return news;
  }

  export async function getAllNews() {
    const news = await fetchAPI("/aktuelles?populate=%2A&sort[0]=updatedAt%3Adesc");
    return news;
  }

  export async function getTermine() {
    const date = new Date();
    const termine = await fetchAPI("/termine?filters[datum][$gte]="+date.toISOString()+"&populate=%2A&sort[0]=datum%3Aasc&pagination[1]=1&pagination[pageSize]=4");
    return termine;
  }

  export async function getAlleTermine() {
    const date = new Date();
    const termine = await fetchAPI("/termine?filters[datum][$gte]="+date.toISOString()+"&populate=%2A&sort[0]=datum%3Aasc");
    return termine;
  }

  export function getNewsImage(item) {    
    let imgURL = "/uploads/thumbnail_tcanews_1_e3782f45ce.png";

    if ( item.attributes.hauptbild.data !== null) {
      imgURL = item.attributes.hauptbild.data.attributes.formats.thumbnail.url;
    } 

    const requestUrl = getStrapiURL(imgURL);
    return requestUrl;      
  }

  export async function getGalleryItems() {
    const gallery = await fetchSimpleContentAPI("/gallerie?populate=%2A");    
    return gallery.data.attributes.bild.data;
  }

  export async function getClubVorstand(){
    const vorstand = await fetchSimpleContentAPI("/vorstands?sort[0]=sortflag&populate=%2A");    
    return vorstand;
  }

  export function getClubVorstandImage(item) {          
    const requestUrl = getStrapiURL("/"+item.attributes.Bild.data.attributes.url);
    return requestUrl;      
  }

  export async function getPartner(){
    const partner = await fetchSimpleContentAPI("/sponsoren?populate=%2A");    
    return partner;
  }

  export function getPartnerImage(item) {          
    const requestUrl = getStrapiURL("/"+item.attributes.logo.data.attributes.url);
    return requestUrl;      
  }

  export async function getNewsItem(id) {          
    const item = await fetchSimpleContentAPI("/aktuelles/"+id);
    return item.data.attributes;      
  }

  export async function getStartseiteBilder() {          
    //const item = await fetchSimpleContentAPI("/startseite-bilders?filters[kategorie][$eq]="+kategorie+"&populate=%2A");
    const item = await fetchSimpleContentAPI("/startseite-bilders?populate=%2A");
    return item.data;      
  }

  export function getStartseiteBilderObjekt(item) {          
    const requestUrl = getStrapiURL("/"+item.attributes.bild.data.attributes.formats.small.url);    
    return requestUrl;      
  }
  
  export async function doLogin(email,password){
       
    try {
      const res =  await axios.post(getStrapiURL('/auth/local'), {
        identifier: email,
        password: password
      }, {         
        headers: {
          "Content-Type": "application/json",              
        }
      });
      
      return { data: res.data, error: null };
    } catch (err) {      
      return { data: null, error: err };
    }        
}

export async function getClubmeisterschaftenRemote() {      
  
  try {            
    const item = await axios.get(getStrapiURL('/clubmeisterschafts'), {         
      headers: {
        "Content-Type": "application/json", 
        "Authorization": "Bearer " + localStorage.getItem("site")
      }
    })          
    return item.data;
  } catch (e) {
    console.error(e, e.stack);
    throw new Error ('Ups...das hätte nicht passieren dürfen. Fehler holen der Clubmeisterschaften');
  }  
      
}