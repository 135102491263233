import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { getNewsImage } from "../utils/api"

export default function NewsItem (props) {

    return (
        <>
        <Link className="news-link pt-3" to={`/news/${props.item.id}`}>
            <img className="img-fluid rounded" src={getNewsImage(props.item)} alt="TCA news" ></img>
            <br/>{props.item.attributes.titel}  
            
        </Link>
        <p className="text-muted fs--1 stretched-link text-decoration-none" >
          <Moment format="DD.MM.YYYY">
          {props.item.attributes.updatedAt}
          </Moment>
        </p>
        </>
    );
}
