import React from "react";
import SimpleContentSection from "./SimpleContentSection";
import { Link } from 'react-router-dom';

export default class IntroGastro extends React.Component {

  render() {
    
    return <section className="p-2 m-1 bg-black">
              <div className="container-fluid">    
                <div className="row nopadding justify-content-center">
                  <div className="col-md-4">
                    <img src="https://tc-angertal.de/strapi/uploads/small_Trattoria_del_Sole_4ce4bc23bd.jpg" alt="Trattoria" className="img-fluid" />
                  </div>
                  <div className="col-md-4 text-center">
                                          
                    <SimpleContentSection path="/intro-gastro" textClassName="text-introclub"/>

                    <Link className="btn btn-info me-2" to="https://trattoria-del-sole-ratingen.eatbu.com/?lang=de" target="_blank">Homepage</Link>
                    
                  </div>
                </div>

              </div>
            </section>;         
  }
  

}