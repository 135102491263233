
import React, {useState, useEffect} from "react";
import { getAllNews } from "../utils/api"
import NewsItem from "../components/NewsItem";

export default function NewsItemsView()  {

    const [newsItems,setNewsItemsState] = useState([]);
    
    useEffect( () => {

        const fetchData = async () => {
          try {
            const data = await getAllNews();                  
            setNewsItemsState(data.data)
          } catch (e) {
            console.log(`Axios request failed: ${e}`);
          }
        }
        fetchData()
           
        }, []
      )

   
    return (
        <section className="section-page">
            <div className="container">
              <div className="section-title">
                <h2>Alle News</h2>
              </div>
                <div className="row row-cols-1 row-cols-md-3 align-items-center">
                {newsItems.map((item) => (
                <div className="col"  key={item.id}>
                    <NewsItem item={item}/>
                    
                </div>
                ))
                }
               </div>
            </div>
        </section>);
  
}