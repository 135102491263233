
import React from "react";
import SimpleContentSection from "../components/SimpleContentSection";
import LKRace from "../components/LKRace";
import { useSearchParams } from 'react-router-dom';

export default function TennisView () {

    const [searchParams, setSearchParams] = useSearchParams()
 
    
    return ( <section className="section-page">
            <div className="container">

                {searchParams.get('hideheader') == null &&
                <div className="section-title pb-0">
                    <h2>Tennis</h2>
                  </div>   
                }

                  <div className="row" >
                    <div className="col">
                    <ul id="myTab" className="nav nav-pills nav-fill">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="tab1" data-bs-toggle="tab" data-bs-target="#tab1-pane" type="button" role="tab" aria-controls="tab1-pane" aria-selected="true">
                            Aktivitäten</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="tab2" data-bs-toggle="tab" data-bs-target="#tab2-pane" type="button" role="tab" aria-controls="tab2-pane" aria-selected="false">
                            Tennisschule</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="tab3" data-bs-toggle="tab" data-bs-target="#tab3-pane" type="button" role="tab" aria-controls="tab3-pane" aria-selected="false">
                            Platz buchen</button>
                      </li>                     
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="tab4" data-bs-toggle="tab" data-bs-target="#tab4-pane" type="button" role="tab" aria-controls="tab4-pane" aria-selected="false">
                            Tennishalle</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="tab5" data-bs-toggle="tab" data-bs-target="#tab5-pane" type="button" role="tab" aria-controls="tab5-pane" aria-selected="false">
                            Rangliste</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="tab6" data-bs-toggle="tab" data-bs-target="#tab6-pane" type="button" role="tab" aria-controls="tab6-pane" aria-selected="false">
                            Wingfield</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="tab7" data-bs-toggle="tab" data-bs-target="#tab7-pane" type="button" role="tab" aria-controls="tab7-pane" aria-selected="false">
                            Ballmaschine</button>
                      </li>
                    </ul>
                    </div>
                  </div>   

                  <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="tab1-pane" role="tabpanel" aria-labelledby="tab1" tabIndex="0">
                    <SimpleContentSection path="/tennis" queryField="aktivitaeten"></SimpleContentSection>
                    <LKRace />
                    </div>
                    <div className="tab-pane fade" id="tab2-pane" role="tabpanel" aria-labelledby="tab2" tabIndex="0">
                      <SimpleContentSection path="/tennis" queryField="tennisschule"></SimpleContentSection>
                    </div>
                    <div className="tab-pane fade" id="tab3-pane" role="tabpanel" aria-labelledby="tab3" tabIndex="0">
                        <SimpleContentSection path="/tennis" queryField="platzbuchen"></SimpleContentSection>
                    </div>
                    <div className="tab-pane fade" id="tab4-pane" role="tabpanel" aria-labelledby="tab4" tabIndex="0">
                      <SimpleContentSection path="/tennis" queryField="tennishalle"></SimpleContentSection>
                    </div>
                    <div className="tab-pane fade" id="tab5-pane" role="tabpanel" aria-labelledby="tab5" tabIndex="0">
                      <SimpleContentSection path="/tennis" queryField="rangliste"></SimpleContentSection>
                    </div>
                    <div className="tab-pane fade" id="tab6-pane" role="tabpanel" aria-labelledby="tab6" tabIndex="0">
                      <SimpleContentSection path="/tennis" queryField="wingfield"></SimpleContentSection>
                    </div>
                    <div className="tab-pane fade" id="tab7-pane" role="tabpanel" aria-labelledby="tab7" tabIndex="0">
                      <SimpleContentSection path="/tennis" queryField="ballmaschine"></SimpleContentSection>
                    </div>
                  </div>
                  
              </div>      
          </section>
    )
  }