import React from "react";

import Navbar3 from "./Navbar3";
import {Routes, Route } from 'react-router-dom'
import Home from '../views/HomeView';
import Gallery from './Gallery';
import Padel from "./Padel";
import Club from "./Club";
import NewsItemView from "../views/NewsItemView";
import NewsItemsView from "../views/NewsItemsView";
import Partner from "./Partner";
import Footer from "./Footer";
import Impressum from "./Impressum";
import Datenschutz from "./Datenschutz";
import MitgliedWerden from "./MitgliedWerden";
import RobinsonCupView from "../views/RobinsonCupView";
import TennisView from "../views/TennisView";
import TermineView from "../views/TermineView";
import LoginView from "../views/LoginView";
import ClubmeisterschaftenView from "../views/ClubmeisterschaftenView";
import { useSearchParams } from 'react-router-dom';
import AuthProvider from "../hooks/AuthProvider";
import PrivateRoute from "./PrivateRoute";

export default function Layout () {

  const [searchParams, setSearchParams] = useSearchParams(); 

      return(
           <div>        
            {searchParams.get('hideheader') == null &&      
                  <Navbar3/>
            }
                  <AuthProvider>
                    <Routes>
                        <Route exact path="/" element={<Home/>} />
                        <Route path="/galerie" element={<Gallery/>} />
                        <Route path="/tennis" element={<TennisView/>} />
                        <Route path="/padel" element={<Padel/>} />
                        <Route path="/club" element={<Club/>} />
                        <Route path="/partner" element={<Partner/>} />
                        <Route path="/news" element={<NewsItemsView/>} />                        
                        <Route path="/news/:id" element={<NewsItemView/>} />
                        <Route path="/impressum" element={<Impressum/>} />
                        <Route path="/datenschutz" element={<Datenschutz/>} />
                        <Route path="/mitgliedwerden" element={<MitgliedWerden/>} />
                        <Route path="/robinsoncup" element={<RobinsonCupView/>} />                        
                        <Route path="/termine" element={<TermineView/>} />
                        <Route path="/login" element={<LoginView/>} />                                    
                        <Route element={<PrivateRoute />}>
                          <Route path="/cm" element={<ClubmeisterschaftenView/>} />
                        </Route>                                                              
                    </Routes>
                  </AuthProvider>
                  
                  {searchParams.get('hideheader') == null && 
                  <Footer/>
                    }
            </div>        
      )
   }