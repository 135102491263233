import React, { useEffect, useState } from "react";
import { getPartner, getPartnerImage } from "../utils/api";
import Carousel from 'better-react-carousel';

export default function PartnerSection()  {

  const [partnerState,setPartnerState] = useState([]);
 
  useEffect( () => {

    const fetchData = async () => {
      try {
        const data = await getPartner();                  
        setPartnerState(data.data)        
      } catch (e) {
        console.log(`Axios request failed: ${e}`);
      }
    }
    fetchData()
       
    }, []
  )
   
    return (<section className="section-page">
              <div className="container">

              <div className="section-title">
                <h3>TC Angertal bedankt sich bei allen Partnern & Sponsoren!</h3>
              </div>
              
              <div>
                <Carousel cols={3} rows={1} loop autoplay={3000}>
                   {partnerState.map((item,i) => (

                  <Carousel.Item key={i}>
                    <img src={getPartnerImage(item)} alt={item.attributes.name}/>
                  </Carousel.Item>

                  ))
                }

              </Carousel>              
              </div>
               </div>
               </section>
            );
  
}