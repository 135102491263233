import React from "react";
import SimpleContentSection from './SimpleContentSection';

export default function Impressum()  {
   
    return (<section className="section-page">
              <div className="container">

              <div className="section-title">
                <h2>Impressumn</h2>
              </div>

              <SimpleContentSection path="/impressum" bgcolor="" />

                
               </div>
               </section>
            );
  
}