import { getNews, getTermine } from "../utils/api"
import React from "react";
import Moment from 'react-moment';
import NewsItem from "../components/NewsItem";
import { Link } from 'react-router-dom';

export default class NewsHomeView extends React.Component {
 
  state = {      
    news: [],
    termine: []
  };
  
  render() {
    const { news = [], termine = [] } = this.state;
    return <section className="text-center section-padding" id="news">        
        <div className="container">
        
            <div className="section-title">
              <h2>Termine & News</h2>
            </div>

            <div className="row">
              
                {termine.map((item) => (   
                  <div className="col-md-3" key={item.id}>              
                    
                      <p className="text-muted m-0 p-0"><Moment format="DD.MM.YYYY HH:mm">{item.attributes.datum}</Moment></p>
                      <h5 className="">{item.attributes.titel}</h5>
                      <div className="pb-2"></div>
                    
                  </div>
                  ))
                }   
                <div className="col pb-2">
                <Link className="btn btn-info me-2 " to="/termine">Alle Termine</Link>                       
                </div>
                
              </div>
            </div>

            <div className="row">             
              <hr></hr>             
            </div>
            
            <div className="row">
              {news.map((item) => (
                <div className="col-md-3" key={item.id}>                  
                  <NewsItem item={item}/>
                </div>
                ))
              }
            </div>          
        </section>;
  }


  async componentDidMount() {
    
    try {
      const data = await getNews();            
      const termineData = await getTermine();
      this.setState({ news : data.data, termine : termineData.data })
    } catch (e) {
      console.log(`Axios request failed: ${e}`);
    }

  }

}