
import React, {useState, useEffect} from "react";
import { getStartseiteBilder, getStartseiteBilderObjekt } from "../utils/api"
import PhotoAlbum from "react-photo-album";

export default function StartseiteBilder()  {

  const [bilderState,setBilderState] = useState([]);
  
  useEffect( () => {
    const fetchData = async () => {
      try {
        const data = await getStartseiteBilder()                
        const photos = data.map((item) => (                                       
          {
            src: getStartseiteBilderObjekt(item), 
            width: item.attributes.bild.data.attributes.formats.small.width, 
            height: item.attributes.bild.data.attributes.formats.small.height, 
            title: item.attributes.inhalt,
          })           
        )
        setBilderState(photos)
      } catch (e) {
        console.log(`Axios request failed: ${e}`);
      }
    }
    fetchData();
       
    }, []
  )

 
    return (
      <section className="section-page">
      <div className="container">
            <div className="section-title">
               <h2>Impressionen</h2>
            </div>
          <PhotoAlbum layout="masonry" photos={bilderState}  renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                                                                <div>
                                                                  <a href={photo.href} style={wrapperStyle}>
                                                                    {renderDefaultPhoto({ wrapped: true })}
                                                                  </a>
                                                                  <div
                                                                      style={{
                                                                          paddingTop: "0px",     
                                                                          spacingTop: "0px",                                                                                                                                                                                                                               
                                                                          textAlign: "center",                                                                              
                                                                      }}
                                                                  >
                                                                  <p className="text-muted">{photo.title}</p>
                                                                  </div>
                                                                  </div>
                                                            )}/>            
        </div>
    </section>
    )

}