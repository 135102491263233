import React, { useEffect, useState } from "react";
import { getClubVorstand, getClubVorstandImage } from "../utils/api"

export default function ClubVorstand()  {

  const [teamState,setTeamState] = useState([]);
 
  useEffect( () => {

    const fetchData = async () => {
      try {
        const data = await getClubVorstand();                  
        setTeamState(data.data)
      } catch (e) {
        console.log(`Axios request failed: ${e}`);
      }
    }
    fetchData()
       
    }, []
  )
   
    return (<><h2 id="vorstand" className="text-center">Vorstand</h2>

              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">

                {teamState.map((item,i) => (
                  <div className="row no-gutters" key={i}>
                    <div className="col-3">
                    <img src={getClubVorstandImage(item)} width="50" height="75" alt="vorstand bild"/>         
                    </div>
                    <div className="col-9" >
                      <div className="row">
                        <div className="col">
                          <p>{item.attributes.Position}</p>
                          <p>{item.attributes.Name}</p>
                        </div>
                      </div>
                                                                            
                    </div>
                  </div>
                ))
                }
                                
               </div> 
               </>
            );
  
}