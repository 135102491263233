import { fetchSimpleContentAPI } from "../utils/api"
import React from "react";
import ReactMarkdown from 'react-markdown'
import ImageRenderer from "./ImageRenderer";
import remarkGfm  from 'remark-gfm'

export default class SimpleContentSection extends React.Component { 

  state = {      
    content: null    
  };
   

  render() {
    const { content } = this.state;
    
    return <section className={`${this.props.bgcolor}`}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8"> 
                  <div className={`${this.props.textClassName}`}>
                    <ReactMarkdown components={{ img: ImageRenderer }} remarkPlugins={[remarkGfm]}>                      
                      {content}                      
                    </ReactMarkdown> 
                  </div>
                </div>
              </div>
            </div>
          </section>;
  }

  async componentDidMount() {
    
    try {
      const data = await fetchSimpleContentAPI(this.props.path);      
      let inhalt = null
      if ( this.props.queryField != null){
        inhalt = data.data.attributes[this.props.queryField];      
      } else {
        inhalt = data.data.attributes.inhalt;      
      }
      this.setState({ content : inhalt })
    } catch (e) {
      console.log(`Axios request failed: ${e}`);
    }

  }

}