import React from 'react';

import "./scss/style.scss";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLocationDot, faPhone, faPaperPlane, faArrowUpRightFromSquare, faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import HomeView from './views/HomeView';

library.add(faLocationDot, faPhone, faPaperPlane, faArrowUpRightFromSquare, faAnglesDown);

export default class App extends React.Component {
  render() {
    return <HomeView/>
  }
}