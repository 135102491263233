import React from "react";
import { Link } from 'react-router-dom';
import StartseiteBilder from "./StartseiteBilder";

export default class SimpleContentSection extends React.Component {

  render() {
    
    return <section className="p-2 m-1"><div className="container-fluid">    

                <div className="row nopadding">
                  <div className="col-md-6">
                    <div className="section-title">
                      <h2>Club</h2>
                    </div>
                      
                      <p>Umgeben von Wald und Feld liegt die schöne Clubanlage des TC Angertal</p>
                      <p>Wir lieben den Tennis, Padelsport und das Miteinander. Sei dabei und schlag doch bei uns auf!</p>
                      <Link className="btn btn-info me-2" to="/club">Weitere Infos</Link>
                      <Link className="btn btn-warning me-2" to="/mitgliedwerden">Mitglied werden</Link>

                    </div>
                    <div className="col-md-6 pt-4">
                      <div className="club-background">
                        
                      </div>
                    
                    </div>
                </div>
{/*
                <div className="row nopadding">
                  <StartseiteBilder kategorie="club"/>
                </div>
                */}


                <div className="row nopadding">
                  <div className="col-md-6 nopadding pt-4">
                    <div className="tennis-background">
                      
                    </div>
                  </div>
                  <div className="col-md-6">
                  <div className="section-title">
                    <h2>Tennis</h2>
                  </div>
                    
                    <p>Mit 9 Außen- und 3 Hallentennisplätzen, Flutlicht, Wingfield, einer Ballmaschine und einem super Trainerteam bieten wir eigentlich alles was das Tennisherz höher schlagen läßt.</p>                    
                    <Link className="btn btn-info me-2" to="/tennis">Weitere Infos</Link>
                    <Link className="btn btn-warning me-2" to="/mitgliedwerden">Mitglied werden</Link>
                  </div>
                </div>


                <div className="row pt-4">
                  <div className="col-md-6">
                  <div className="section-title">
                    <h2>Padel</h2>
                  </div>
                    
                    <p>Als erster Tennisclub im Kreis Mettmann wurden März 2023 zwei Padelplätze gebaut. Sie verfügen über Allwetterbelag und Flutlicht und sind somit die meiste Zeit im Jahr bespielbar.</p>
                    <Link className="btn btn-info me-2" to="/padel">Weitere Infos</Link>
                    <Link className="btn btn-warning me-2" to="/mitgliedwerden">Mitglied werden</Link>

                  </div>
                  <div className="col-md-6 pt-4">
                    <div className="padel-background">
                      
                    </div>
                  
                  </div>
                </div>

              </div>
              </section>;         
  }
  

}