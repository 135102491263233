
import React, {useState, useEffect} from "react";
import { getAlleTermine } from "../utils/api";
import Moment from 'react-moment';

export default function TermineView()  {

    const [termineItems,setTermineState] = useState([]);
    
    useEffect( () => {

        const fetchData = async () => {
          try {
            const data = await getAlleTermine();                  
            setTermineState(data.data)
          } catch (e) {
            console.log(`Axios request failed: ${e}`);
          }
        }
        fetchData()
           
        }, []
      )

   
    return (
        <section className="section-page">
            <div className="container">
              <div className="section-title">
                <h2>Alle Termine</h2>
              </div>
                <div className="row row-cols-1 text-center">
                {termineItems.map((item) => (
                <div className="col"  key={item.id}>
                   <p className="text-muted m-0 p-0"><Moment format="DD.MM.YYYY HH:mm">{item.attributes.datum}</Moment></p>
                      <h5 className="">{item.attributes.titel}</h5>
                      <div className="pb-2"></div>
                </div>
                ))
                }
               </div>
            </div>
        </section>);
  
}