import { useState } from "react";
import { useAuth } from "../hooks/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";

export default function LoginView()  {
  const [input, setInput] = useState({
    username: "",
    password: "",
  });
  const auth = useAuth();
  //const { state } = useLocation();
  //const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();    
    if (input.username !== "" && input.password !== "") {
      try {
        auth.loginAction(input);        
        return;
      } catch(err) {
        console.error("Error beim login: " + err)
      }      
        
    } else {
      alert("Deine Anmeldedate sind nicht korrekt");
    }
    
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <section className="section-page">
              <div className="container-sm mx-auto">
              <div className="row justify-content-center align-items-center">
                <div className="col-md-6">
      <form onSubmit={handleLogin}>
        <div>
          <label htmlFor="username">Benutzername:</label>
          <input  
            className="form-control"          
            id="username"
            name="username"
            type="text"            
            onChange={handleInput}
          />
        </div>
        <div> 
          <label htmlFor="password">Passwort:</label>
          <input            
            className="form-control"
            id="password"
            name="password"
            type="password"            
            onChange={handleInput}
          />
        </div>
        <button className="btn btn-primary w-100 mt-2" type="submit">Anmelden</button>
      </form>    

      </div></div>

    </div>
    </section>
  );
};