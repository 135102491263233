export  default  function  ImageRenderer(props) {

    // https://www.dhrutara.com/blogs/react-markdown-custom-renderers

    const  imageSrc = props.src;    
    const  altText = props.alt;
    
    return (
    
    <img data-loading="lazy" data-orig-file={imageSrc}    
    src={imageSrc}    
    alt={altText}
    className="img-fluid"  />

    
    );
    
    }